.partner {
    width: 60%;
    background: #fff;
    margin: 40px auto;
}

.corporate {
    width: 60%;
    background: #fff;
    margin: 40px auto;
}

@media (max-width: 575px) {
    .corporate {
        width: 100%;
        background: #fff;
        margin: 40px auto;
        padding: 10px;
    }
    .partner {
        width: 100%;
        background: #fff;
        margin: 40px auto;
    }
}

.custom-text-color {
    color: #F57B20;
}
.partner-forget {
    color: #F57B20;
}
:where(.css-dev-only-do-not-override-1xg9z9n).ant-input-outlined:focus,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-input-outlined:focus-within {
    border-color: #c9c9c9;
}

.custom-link {
    color: #F57B20;
    text-decoration: none;
}

.custom-submit-button {
    background-color: #10572D;
    border-color: #10572D;
}