.PhoneInputInput {
  flex: 1 1;
  min-width: 0;
  border: 1px solid #898b8d;
  border-radius: 5px;
  padding-left: 15px !important;
}

.input-phone-traveller {
  background-color: white;
  border-radius: 5px;
  width: 60px;
  height: 46px;
  color: black;
  justify-content: center;
}

.form-check-label {
  padding-left: 10px;
  margin-top: 3px;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.9em;
}

.phone-length {
  border: 1px solid #898b8d;
  border-radius: 5px;
  color: black;
  width: 100%;
}

.custom-checkbox {
  color: #f57b20;
}

.PhoneInputCountry {
  background-color: #f9f9f9 !important;
  border-radius: 5px;
  width: 60px;
  height: 43px;
  color: black;
  justify-content: center;
}

.terms-condition-left {
  padding-left: 2px;
  font-size: 16px;
}

.custom-img-car-icon {
  float: right;
}

.custom-coupon-input {
  border: 1px solid #898b8d;
  border-radius: 5px;
}

.custom-input-coupon-code {
  border: 1px solid #898b8d;
  border-radius: 5px;
  padding: 10px 15px !important;
  margin-right: 9px;
}

.custom-li {
  font-size: 15px;
  color: #f57b20;
  font-weight: bold;
}

.custom-img-arrow-swap {
  width: 60px;
}

.custom-link-vehicle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.custom-submit-button {
  background: #10572d;
}

:where(.css-dev-only-do-not-override-1w4v3hc).ant-select
  .ant-select-selection-placeholder {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  color: rgb(0 0 0 / 84%) !important;
  pointer-events: none;
}

:where(.css-dev-only-do-not-override-1w4v3hc).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  position: relative;
  background-color: #ffffff;
  border: none !important;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.8rem;
}

.form-check-input:checked {
  background-color: #10572d !important;
  border-color: #10572d !important;
}

.booking-details {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;
  padding: 20px;
}

.custom-input-booking {
  border: 1px solid #898b8d;
  border-radius: 5px;
  margin-right: 9px;
  padding: 10px 35px !important;
  box-shadow: 0px 0px 0px 0px;
}

.custom-car-location-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.mb-align {
  display: flex;
  float: inline-start;
}

@media screen and (max-width: 900px) and (min-width: 200px) {
  .same-traveller-details {
    position: absolute;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    color: #222;
  }
  .mb-align {
    display: flex;
    float: inline-start;
    margin-top: -11%;
  }
  .mb-vehicle-name {
    margin-bottom: 0.1rem !important;
  }
}

Checkbox {
  accent-color: green !important;
}

/* styles.css */

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #10572d !important;
  border-color: #10572d !important;
}

.ant-checkbox-checked:hover .ant-checkbox-inner:hover {
  background-color: #10572d !important;
  border-color: #10572d !important;
}

.privacy-policy-link:hover{
  color: #ff6f00 !important;
  text-decoration: none;
}