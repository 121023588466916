#find-car-button {
    padding: 14px 30px;
    font-size: 18px;
    background: #125d30;
    border: none;
    color: white;
    font-size: 1.28571rem;
    font-weight: 400;
}
#dashboardButton {
    font-size: 16px;
    padding: 10px;
    background-color: rgb(245, 123, 32); 
}
@media (min-width: 1200px) {
    #find-car-button {
        padding: 14px 30px;
        font-size: 18px;
        color: white;
        background: #125d30;
        border: none;
        font-size: 1.28571rem;
        font-weight: 400;
    }
}