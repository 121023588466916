.faq-section {
    background: #fdfdfd;
    min-height: 100vh;
    padding: 10vh 0 0;
}

.faq-title h2 {
    position: relative;
    margin-bottom: 45px;
    display: inline-block;
    font-weight: 600;
    line-height: 1;
}

.custom-caret {
    float: right;
}

.faq-title h2::before {
    content: "";
    position: absolute;
    left: 50%;
    width: 60px;
    height: 2px;
    background: #E91E63;
    bottom: -25px;
    margin-left: -30px;
}

.faq-title p {
    padding: 0 190px;
    margin-bottom: 10px;
}

.faq {
    background: #FFFFFF;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
    border-radius: 4px;
}

.faq .card {
    border: none;
    background: none;
    border-bottom: 1px dashed #6d6d6d;
}

.faq .card .card-header {
    padding: 0px;
    border: none;
    background: none;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.faq .card .card-header:hover {
    background: rgba(44, 30, 233, 0.1);
    padding-left: 10px;
}

.faq .card .card-header .faq-title {
    width: 100%;
    text-align: left;
    padding: 0px;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0px;
    color: #000;
    text-decoration: none !important;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    padding-top: 20px;
    padding-bottom: 20px;
}

.faq .card .card-header .faq-title .badge {
    display: inline-block;
    /* width: 21px;
    height: 21px; */
    line-height: 16px;
    float: left;
    border-radius: 100px;
    text-align: center;
    background: #125d30;
    color: #fff;
    font-size: 10px;
    margin-right: 10px;
}

.faq .card .card-body {
    padding: 30px;
    padding-left: 35px;
    padding-bottom: 16px;
    font-weight: 400;
    font-size: 16px;
    color: #000;
    line-height: 28px;
    letter-spacing: 0px;
    border-top: 1px solid #c4c4c4;
}

.faq .card .card-body p {
    margin-bottom: 14px;
}

@media (max-width: 991px) {
    .faq {
        margin-bottom: 30px;
    }

    .faq .card .card-header .faq-title {
        line-height: 26px;
        margin-top: 10px;
    }
}

@media (min-width: 1200px) {
    .full-width {
        max-width: 1500px;
    }
}