.custom-link {
    border-radius: 0;
    width: 100%;
    color: black;
    text-align: left;
}

.custom-img-global {
    margin-right: 8px;
    margin-top: -4px;
}
