.faq-section {
    background: #fdfdfd;
    min-height: 100vh;
    padding: 2vh 0 0;
}

.faq-title h2 {
    position: relative;
    margin-bottom: 45px;
    display: inline-block;
    font-weight: 600;
    line-height: 1;
}

.faq-title h2::before {
    content: "";
    position: absolute;
    left: 50%;
    width: 60px;
    height: 2px;
    background: #E91E63;
    bottom: -25px;
    margin-left: -30px;
}

.faq-title p {
    padding: 0 190px;
    margin-bottom: 10px;
}

.faq {
    background: #FFFFFF;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
    border-radius: 4px;
}

.faq .card {
    border: none;
    background: none;
    border-bottom: 1px dashed #6d6d6d;
}

.faq .card .card-header {
    padding: 0px;
    border: none;
    background: none;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.faq .card .card-header:hover {
    background: rgba(44, 30, 233, 0.1);
    padding-left: 10px;
}

.faq .card .card-header .faq-title {
    width: 100%;
    text-align: left;
    padding: 0px;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0px;
    color: #000;
    text-decoration: none !important;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    padding-top: 20px;
    padding-bottom: 20px;
}

.faq .card .card-header .faq-title .badge {
    display: inline-block;
    /* width: 21px;
    height: 21px; */
    line-height: 16px;
    float: left;
    border-radius: 100px;
    text-align: center;
    background: #125d30;
    color: #fff;
    font-size: 10px;
    margin-right: 10px;
}

.faq .card .card-body {
    padding: 15px;
    padding-left: 22px;
    padding-bottom: 16px;
    font-weight: 400;
    font-size: 16px;
    color: #000;
    line-height: 28px;
    letter-spacing: 0px;
    border-top: 1px solid #c4c4c4;
}

.faq .card .card-body p {
    margin-bottom: 14px;
}

@media (max-width: 991px) {
    .faq {
        margin-bottom: 30px;
    }

    .faq .card .card-header .faq-title {
        line-height: 26px;
        margin-top: 10px;
    }
}

.contact-title {
    position: absolute;
    top: 20%;
    left: 49%;
    transform: translate(-50%, -50%);
    color: orange;
    text-decoration: underline;
    text-decoration-color: green;
}

.custom-container {
    background-color: #10572d;
    padding: 52px;
    color: white
}

.contactus-container {
    background-color: #fff;
    margin-top: -140px;
    /* z-index: 0; */
    border-radius: 20px;
    /* opacity: 0.9; */
    margin-left: 167px;
    margin-right: 188px;
}

#name {
    background-color: #fdfdfd;
    border: 1px solid #898b8d;
    border-radius: 5px;
    color: #526977;
    outline: none !important;
    padding: 10px 15px;
    transition: all .3s;
    width: 100%;
}

#email {
    background-color: #fdfdfd;
    border: 1px solid #898b8d;
    border-radius: 5px;
    color: #526977;
    outline: none !important;
    padding: 10px 15px;
    transition: all .3s;
    width: 100%;
}

/* #spacing {
    padding: 0px;
} */

@media only screen and (min-width: 600px) {
    .contactus-container {
        margin-left: 140px;
        margin-right: 140px;
    }
}

.custom-border {
    border: 2px solid #e9e1e1;
    border-radius: 20px;
    margin-left: 10px;
}


.contact-body-custom {
    height: 174px;
    background-color: #f0ebeb;
    text-align: start 10px;
}

.contact-header {
    background-color: #ffc107;
    color: #333;
}

.contact-title-custom {
    color: #0a0a09;
    margin-left: 12%;
    text-align: center;
    border-radius: 50%;
    margin-top: 2px;
}

.contact-text-custom {
    color: #24680a;
    margin-left: 20%;
    text-align: left;
}

.contact-contact-custom {
    color: #24680a;
    margin-left: 31%;
    text-align: left;
}

.contact-custom {
    width: 21rem;
    float: right;
    margin-right: -75px;
    margin-left: 30px;
    border: 3px solid orange;
    border-radius: 5px;
}


.row-custom-spacing {
    margin-left: 40px;
    margin-right: 69px;
    margin-bottom: 0px;
    background-color: #fff;
    padding-top: 80px;
}


.contact-btn {
    display: inline-block;
    padding: 8px 20px 10px 10px;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    font-weight: bold;
    color: #fff;
    width: auto;
    height: 40px;
    margin-left: 26%;
    margin-top: auto;
}

.contact-title i {
    border-radius: 100%;
    background-color: #f90;
    color: #fff;
}

.top-white-border {
    border-top: 90px solid rgb(211, 208, 208);
    z-index: 30;
}

.new-button {
    background-color: #f57b20;
    color: white;
    padding: 10px 10px;
    border: 2px;
    cursor: pointer;
    border-radius: 10px;
    width: 120px;
}

.row justify-content-end {
    padding-top: 600px;
    padding-bottom: 200px;
    padding-left: 50-px;
    border-top-right-radius: 50px;
    --bs--gutter-x: 80px;
}

#message {
    display: block;
    width: 100%;
    padding: 7px 5px 4px 10px;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.custom-image-fluid {
    width: 52px;
    height: 48px;
    margin-bottom: -91px;
    padding-left: 5px;
}

@media screen and (max-width: 900px) and (min-width: 200px) {
    .contact-custom {
        width: 100%;
        float: initial;
        margin-right: -0px;
        margin-left: 0px;
        border: 3px solid orange;
        border-radius: 5px;
    }

    #spacing {
        padding: 10px;
    }

    .contact-body-custom {
        height: 140px;
        background-color: #f0ebeb;
        text-align: start 10px;
    }

    .contact-btn {
        display: inline-block;
        padding: 8px 20px 10px 10px;
        text-align: center;
        text-decoration: none;
        border-radius: 4px;
        font-weight: bold;
        color: #fff;
        width: auto;
        height: 40px;
        margin-left: 30%;
        margin-top: auto;
    }

    .custom-image-fluid {
        width: 60px;
        height: 55px;
        margin-bottom: -91px;
        padding-left: 5px;
        margin-left: 35px;
    }

    .contactus-container {
        background-color: #fff;
        margin-top: -140px;
        /* z-index: 0; */
        border-radius: 20px;
        /* opacity: 0.9; */
        margin-left: 0px;
        margin-right: 0px;
    }

    .row-custom-spacing {
        margin-left: 0px;
        margin-right: 0%;
        margin-bottom: 0px;
        background-color: #fff;
        padding-top: 80px;
    }

    .contact-text-custom {
        color: #24680a;
        margin-left: 34%;
        text-align: left;
    }
}

.img-fluid-loading {
    width: 17%;
    margin-left: 45%;
    margin-top: 20%;
}