.custom-faq-section {
    padding: 10px;
}
.custom-country-details {
    padding: 15px;
    padding-left: 16px;
    padding-bottom: 16px;
    font-weight: 400;
    font-size: 16px;
    color: #000;
    line-height: 28px;
    letter-spacing: 0px;
    border-top: 1px solid #c4c4c4;
}