.why-choose-us-slider {
  padding: 20px;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.why-choose-us-card {
  margin: 10px;
  border: none;
  border-radius: 15px;
  overflow: hidden;
  transition: transform 5.6s ease, box-shadow 5.6s ease;
}

.why-choose-us-card:hover {
  transition: transform 0.6s ease, box-shadow 0.6s ease;
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.card-img-container {
  position: relative;
  overflow: hidden;
  border-radius: 15px 15px 0 0;
}

.card-img-top {
  display: block;
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.why-choose-us-card:hover .card-img-top {
  transform: scale(1.1);
  transition: transform 0.6s ease, box-shadow 0.6s ease;
}

.card-img-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  transition: background 0.6s ease-in-out;
  right: 0;
  background: linear-gradient(
    to top,
    rgb(0 0 0 / 83%) 25%,
    rgba(0, 0, 0, 0.01) 60%
  );
  color: white;
  padding: 20px;
  transition: background 0.3s ease;
}

.card:hover .card-img-overlay {
  transition: transform 0.6s ease, box-shadow 0.6s ease;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3));
}

.why-choose-us-card-title {
  margin: 0;
  margin-bottom: 0px;
  font-size: 1.25rem;
  font-weight: 300;
  letter-spacing: 0.5px;
}

.why-choose-us-row {
  display: flex;
}

.pc-2 {
  padding: 0.5rem !important;
}
.wcu-fw {
  font-size: 22px !important;
}
