.enquiry-section {
    border-radius: 10px;
    box-shadow: 0 0 12px 0 #c3c3c3;
    padding: 15px;
}
@keyframes bounce {
    0% {
        transform: translateY(0) rotateX(0deg);
    }
    50% {
        transform: translateY(-10px) rotateX(90deg);
    }
    100% {
        transform: translateY(0) rotateX(0deg);
    }
}
.registrar-container {
    animation: bounce 1s;
    margin-bottom: 5rem; /* Equivalent to mb-5 in Bootstrap */
    padding-top: 5rem; /* Equivalent to pt-5 in Bootstrap */
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-input-outlined:hover {
    border-color: #115C2F;
    background-color: #ffffff;
}
