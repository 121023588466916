.card {
    transition: box-shadow 0.3s;
}

.card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.card-text {
    font-size: 0.9rem;
    margin-bottom: 0.2rem;
}

.btn-group {
    margin-top: 1rem;
}

.profile-width {
    width: 50px;
    height: 50px;
}

.custom-list-group {
    border-radius: 10px;
}

.custom-list-item {
    background-color: #fff;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.custom-booking-details {
    padding: 20px;
    max-width: 70%;
}

.custom-section {
    padding: 0;
    width: auto;
}

.custom-booking-id {
    color: #167247;
    font-weight: 600;
}

.custom-booking-info {
    font-size: 15px;
    color: #232323;
}

.custom-booking-actions {
    padding: 20px;
}

.custom-view-booking-btn,
.custom-cancel-booking-btn {
    font-size: 14px;
    padding: 8px 16px;
}

.custom-view-booking-btn {
    background-color: #198754 !important;
    border-color: #198754 !important;
}

.custom-view-booking-btn:hover {
    background-color: #176b29 !important;
    border-color: #176b29 !important;
}

.custom-cancel-booking-btn {
    background-color: #f38920;
    border-color: #f38920;
    color: white;
}

.custom-cancel-booking-btn:hover {
    background-color: #e07f1d;
    border-color: #e07f1d;
    color: #fff;
}

.custom-pagination {
    text-align: center;
}

.custom-prev-btn,
.custom-next-btn {
    font-size: 14px;
}

.pagination .page-item .page-link {
    background-color: #1f7342;
    border: none;
    border-radius: 30px;
    color: #fff;
}

/* a {
    color: #000;
} */
.profile-details {
    margin-top: 20px;
}

.rounded-circle-profile-icon {
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
}

.rounded-circle:hover {
    transform: scale(1.05);
}

.detail {
    /* display: flex; */
    /* padding: 7px;
    border-radius: 10px; */
    background-color: #157347;
    border: #157347;
    /* margin-bottom: 7px; */
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.process-height {
    height: 10px;
}
.nav-link-profile.active {
    background-color: #f0f0f0; 
}
.nav-link-profile.active .text-decoration-none {
    color: #157347;
}

.btn-view-all {
    background: #f57b20;
    color: #fff;
}

.btn-view-all:hover {
    background: #cf6718;
    border: 1px solid #cf6718;
    color: #fff;
}

.booking-status {
    font-weight: bold;
}

.left-space {
    margin-left: 5px;
    margin-right: 5px;
}

.img-progress-width {
    width: 12%;
}

.img-progress-photo-width {
    width: 18%;
}

.img-progress-email-width {
    width: 15%;
}

.img-progress-other-details-width {
    width: 16%;
}

.label {
    font-weight: bold;
}

.progress {
    margin-top: 10px;
}

.process-bar-leftalign {
    text-align: left;
}

.progress-bar {
    height: 100%;
}

.arrow {
    font-size: 18px;
}

.active-tab {
    background-color: rgb(255, 255, 255);
    color: #f57b20;
}

.text-color {
    color: #262626;
}

.view-booking {
    background: #157347 !important;
    border: 1px solid #157347 !important;
}

.cancel-booking {
    background: #f57b20 !important;
    border: 1px solid #f57b20;
}

.cancel-booking-width {
    width: 900px;
    background: #fff;
    margin: 0 auto;
    /* box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05); */
    -moz-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 1px 1px 20px 5px rgb(0 0 0 / 18%);
    -o-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    -o-border-radius: 20px;
    -ms-border-radius: 20px;
}

.cancel-booking:hover {
    background: #df711d;
    border: 1px solid #df711d;
}

.view-booking:hover {
    background: #157347 !important;
    border: 1px solid #157347 !important;
}

.custom-prev-btn:hover,
.custom-next-btn:hover {
    background-color: #f8f9fa;
}

.custom-tab-style .nav-link:hover {
    background-color: #dbdbda;
}

.custom-tab-style .nav-link:focus {
    background-color: #e07f1d;
    color: #fff;
}

#cancellation-border {
    border-bottom: 1px solid #e3e3e3;
}

.car-image {
    max-width: 30%;
    margin-right: 20px;
}

.center-text {
    text-align: center;
}

.booking-section {
    display: flex;
    align-items: center;
}

.booking-details {
    max-width: 100%;
    width: 100%;
}

.custom-booking-id {
    margin-bottom: 2px;
    padding-top: 2px;
}

.custom-booking-info {
    margin-bottom: 1px;
}

.custom-booking-actions {
    margin-left: 3px;
}

.custom-img {
    width: 60px;
}

.custom-margin-bottom {
    margin-bottom: 5px;
}

.custom-pagination {
    margin-top: 4px;
}

.custom-section {
    padding: 0px;
    width: auto;
}

.custom-navbar-nav {
    margin: auto;
}

.custom-page-item {
    padding: 5px;
}

.custom-booking-section {
    padding: 20px 0;
    width: 100%;
}

.height-fixed {
    position: sticky;
    top: 10px;
}

.pick-location {
    color: #157347;
}

.drop-location {
    color: #157347;
}

.btn-cancel-booking {
    width: max-content;
}

@media (max-width: 1031px) {
    .mb-dashboard-padding {
        padding: 10px;
    }

    .mb-active-booking {
        padding-top: 10px;
    }

    .mb-profile {
        padding: 10px;
    }

    .mb-cancel-booking {
        float: inline-end;
        margin-top: -13%;
    }

    .mb-center-align {
        margin-left: 22%;
        margin-top: 5%;
    }

    .profile-tab-padding {
        padding: 13px;
        margin-bottom: 9px;
        border: 1px solid #d2d2d2;
    }

    #cancellation-border {
        border: 1px solid #d2d2d2;
    }

    .mb-dashboard-padding {
        padding: 15px;
    }

    .custom-margin-bottom {
        margin-bottom: 0px;
    }

    .cancel-booking-width {
        width: 100%;
        background: #fff;
        margin: 0 auto;
        -moz-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
        -webkit-box-shadow: 1px 1px 20px 5px rgb(0 0 0 / 18%);
        -o-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
        -ms-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
        border-radius: 20px;
        -moz-border-radius: 20px;
        -webkit-border-radius: 20px;
        -o-border-radius: 20px;
        -ms-border-radius: 20px;
    }

    .mb-close-align {
        float: inline-end;
        margin-top: -34px;
    }
}

.detail:hover{
    background-color: #024b29;
}

.dashboard-manage-account-btn{
    background-color: #157347;
    color: #ffffff;
}

.dashboard-manage-account-btn:hover{
    background-color: #185f3e;
    color: #ffffff;
}