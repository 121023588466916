.swiper-container {
    width: 100%;
    height: 100%;
    padding-top: 100px;

}

.main-single-view {
    flex-basis: 500px;
    width: 70%;

}

.swiper-slide {
    width: 400px;
}

.team-info {
    padding: 0;
    list-style: none;
    position: relative;
}

.home-testimonial {
    background-color: #ececec !important;
    height: 31em;
}

.business {
    margin-top: 5px;
    margin-right: 590px;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    text-align: left;
    line-height: 1.6;
}

.team-info .team-info-item {
    font-size: 22px;
    padding: 15px 30px;
    display: none;
    position: absolute;

}

.team-info .team-info-item.active {
    display: block;
}


/* @-webkit-keyframes team-info-item {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50px, 0);
  }
  
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
  }
  } */


.team-info-item.active .heading,
.team-info-item.active .content {
    -webkit-animation-name: team-info-item;
    animation-name: team-info-item;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-delay: 0s;
}

.team-info-item.active .content {
    -webkit-animation-delay: .2s;
}

.heading {
    margin: 0;
}

/* .content {
      font-size: 19px;
    font-weight: 300;
    line-height: 1.5;
    color: #000;
    margin-top: 15px;
  } */

.fsocial {
    list-style: none;
    padding: 0;
    display: flex;

}

.fsocial li {
    margin: 5px 15px;
    margin-bottom: 50px;

}

.fsocial li:first-child {
    margin-left: 0;
}

.fsocial li img {
    width: 30px;
    filter: invert(1);
}

.exp-time {
    list-style: none;
    border-left: 2px solid #000;
    padding-left: 20px;
    margin-left: 0px;
}

.exp-time li {
    position: relative;
    margin-bottom: 50px;
}

.exp-time li:before {
    content: '';
    height: 10px;
    width: 10px;
    background: #000;
    display: block;
    position: absolute;
    left: -26px;
}

.fw300 {
    font-weight: 300;

}

.exp-time h4,
.exp-time h2,
.exp-time p {
    margin: 0;

}

.swiper-slide:hover {
    cursor: ew-resize;
}

.arrow-box {
    position: fixed;
    width: 30%;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.16);
    padding: 5px 50px;
}

.arrow-box .slideNext,
.arrow-box .slidePrev {
    transition: all .3s;
    cursor: pointer;

}

.arrow-box .slidePrev:hover {
    transform: translateX(-10px);
}

.arrow-box .slideNext:hover {
    transform: translateX(10px);
}

.arrow-box svg {
    width: 30px;
}

.green-border {
    border: 1px solid #c4c4c4;
    padding: 32px;
    border-radius: 11px;
    background-color: rgb(245, 241, 241);
}

.img-fluid {
    width: 100%;
    height: auto;
}

/* .card {
    height: 500px;
    width: auto;
    background-color: rgb(240, 240, 240);
    border: none;
    border-radius: 13px;
    border: 2px solid #dfdada;
    padding: 15px;
    box-shadow: 8px 10px 10px 8px rgba(0, 0, 0, 0.1);
  } */

/* .list-group-item {
    background-color: rgb(240, 240, 240);
    height: auto;
  } */


.carousel-control-prev-icon {
    background-color: black;
    margin-right: 2.5em;
    border-radius: 20px;
}

.carousel-control-next-icon {
    margin-left: 2.5em;
    background-color: black;
    border-radius: 20px;
}


/* .carousel-control-next-icon {
    position:inline-block;
    display: auto;
    width: 2rem;
    height: 2rem;
    background-repeat: repeat-x;
    background-position: 50%;
    background-size: 100% 100%;
    animation: slideCarousel 1s infinite;
  
  } */

/* 
  .carousel-control-prev{
    cursor: pointer;
    height: 20px;
    top: 280px;
  }
  .carousel-control-next{
    cursor: pointer;
    height: 20px;
    top: 280px;
  } */

/* Custom styles for carousel control buttons */
.custom-carousel-control {
    background-color: transparent;
    border: none;
    color: #fff;
    /* Set the desired color */
    font-size: 24px;
    /* Adjust the font size as needed */
    transition: color 0.3s;
    /* Add a smooth color transition */
}

.custom-carousel-control:hover {
    color: #007bff;
    /* Change color on hover to a more prominent color */
}


@media (min-width: 1200px) {
    .full-width {
        max-width: 1500px;
    }
}

@media screen and (max-width: 900px) and (min-width: 200px) {
    .carousel-control-next-icon {
        position: inline-block;
        /* display: none; */
        width: 2rem;
        height: 2rem;
        display: none;
        background-repeat: repeat-x;
        background-position: 50%;
        background-size: 100% 100%;
        animation: slideCarousel 1s infinite;
    }

    /* .green-border {
  
        padding: 95px;
        border-radius: 13px;
  
    } */

    /* .img-fluid {
        width: 100%;
        height: auto;
        margin-left: -27px;
        margin-top: -65px;
    } */

    /* .card {
        margin-left: -46px;
        margin-top: 17px;
        height: 530px;
        background-color: rgb(240, 240, 240);
        border: none;
        border-radius: 13px;
        border: 2px solid #dfdada;
        padding: 15px;
        box-shadow: 8px 10px 10px 8px rgba(0, 0, 0, 0.1);
    } */
    /* .carousel-control-prev{
        cursor: pointer;
        height: 443px;
        top: 280px;
    }
    .carousel-control-next{
        cursor: pointer;
        height: 443px;
        top: 280px;
    } */

}

#page4 {
    height: 600px;
    background-color: #0edbd9;
}

.base {
    text-align: left;
    color: #058989;
    font-family: 'Source Sans Pro', sans-serif;
    padding-top: 45px;
    margin-right: 350px;
    margin-left: 190px;
    font-size: 50px;
    padding-bottom: 40px;
}

.custom-table {
    width: 65%;
    margin: 0 auto;
}

.custom-row {
    display: flex;
    margin-top: -10px;
}

.custom-header {
    flex: 1 1;
    text-align: center;
    padding: 5px 14px;
    border-right: 2px solid #FFBD26;
    height: 84px;
}

.custom-data {
    flex: 1;
    text-align: center;
    padding: 0 15px;
    border-right: 2px solid #FFBD26;
    height: 80px;
}

.custom-row:last-child .custom-header,
.custom-row:last-child .custom-data {
    border-right: 0;
    /* Remove border-right from the last item in each row */
}

.custom-header {
    font-family: 'Secular One', sans-serif;
    font-size: 50px;
}



#sin2 {
    width: 60%;
    float: left;
    margin-top: -365px;
    margin-left: 23rem;
}

.bottone3 {
    background-color: transparent;
    font-family: 'Hind', sans-serif;
    font-weight: bold;
    font-size: 14px;
    color: #0c0c0c;
    margin-top: 25px;
    width: 35%;
    border: 1px solid #0c0c0c;
    padding: 10px;
    cursor: pointer;
}

.bottone3:hover {
    opacity: 0.6;
    background: #d2cde0 !important;
}

.valigia {
    width: 28%;
    position: absolute;
    margin-top: -279px;
    margin-left: 735px;
    filter: drop-shadow(11px 11px 11px #058989);
}

/* Footer*/

#footer {
    height: 200px;

}


ul.mainmenu2 {
    margin: 0;
    padding: 0;
    display: flex;
}

ul.mainmenu2 li {
    list-style: none;
    margin-left: -15px;
}



.mainmenu2 li a:hover {
    color: #F8A426;
}



.socialmedia2 {
    float: right;
    margin-top: -70px;
    margin-right: 35px;

}



#collegamenti {
    margin-left: 15px;
    list-style-type: none;
    margin-top: 10px;
    padding: 0;
    overflow: hidden;
}

#collegamenti>li a {

    color: #e5e5e5;
    font-size: 13px;
    text-align: center;
    margin: 4px;
    padding: 1px 12px;
    text-decoration: none;
    font-family: 'Hind', sans-serif;
}

#collegamenti>li a:hover {
    color: #F8A426;
}

.final {
    float: right;
    margin-right: 50px;
    text-align: right;
    color: #e5e5e5;
    font-size: 13px;
}


.base {
    text-align: left;
    color: #058989;
    font-family: 'Source Sans Pro', sans-serif;
    padding-top: 45px;
    margin-right: 350px;
    margin-left: 190px;
    font-size: 50px;
    padding-bottom: 40px;
}

/* Footer*/

#footer {
    height: 200px;

}


ul.mainmenu2 {
    margin: 0;
    padding: 0;
    display: flex;
}

ul.mainmenu2 li {
    list-style: none;
    margin-left: -15px;
}



.mainmenu2 li a:hover {
    color: #F8A426;
}



.socialmedia2 {
    float: right;
    margin-top: -70px;
    margin-right: 35px;

}



#collegamenti {
    margin-left: 15px;
    list-style-type: none;
    margin-top: 10px;
    padding: 0;
    overflow: hidden;
}

#collegamenti>li a {

    color: #e5e5e5;
    font-size: 13px;
    text-align: center;
    margin: 4px;
    padding: 1px 12px;
    text-decoration: none;
    font-family: 'Hind', sans-serif;
}

#collegamenti>li a:hover {
    color: #F8A426;
}

.final {
    float: right;
    margin-right: 50px;
    text-align: right;
    color: #e5e5e5;
    font-size: 13px;
}


.base {
    text-align: left;
    color: #058989;
    font-family: 'Source Sans Pro', sans-serif;
    padding-top: 45px;
    margin-right: 350px;
    margin-left: 190px;
    font-size: 50px;
    padding-bottom: 40px;
}

/*===== SLIDER THREE =====*/
.slider-three {
    background-color: var(--light-2);
    padding-top: 50px;
    padding-bottom: 50px;
}

.slider-three .section-title .title {
    font-size: 44px;
    line-height: 55px;
    font-weight: 600;
    color: var(--black);
}

@media (max-width: 767px) {
    .slider-three .section-title .title {
        font-size: 28px;
        line-height: 32px;
    }
}

.slider-three .section-title .text {
    color: var(--dark-3);
    margin-top: 24px;
}

.slider-three .slider-items-wrapper {
    position: relative;
}

.slider-three .slider-items-wrapper .tns-nav {
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.slider-three .slider-items-wrapper .tns-nav button {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background-color: var(--primary);
    opacity: 0.5;
    border: 0;
    margin: 0 5px;
    -webkit-transition: all 0.4s ease-out 0s;
    -moz-transition: all 0.4s ease-out 0s;
    -ms-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
}

.slider-three .slider-items-wrapper .tns-nav button.tns-nav-active {
    width: 20px;
    opacity: 1;
    border-radius: 5px;
}

.slider-three .single-items-one {
    margin-top: 40px;
}

.slider-three .single-items-one img {
    width: 100%;
    box-shadow: var(--shadow-2);
    border-radius: 10px;
}

.vehicle-card__image-link img {
    transition: transform 0.5s ease-in-out;
}

.vehicle-card__image-link:hover img {
    animation: rotateLeftToRight 1s forwards;
}

@keyframes rotateLeftToRight {
    from {
        transform: rotateY(0deg);
    }

    to {
        transform: rotateY(360deg);
    }
}

.custom-cursor {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
}

.why-choose-us {
    margin-top: 4rem;
    min-height: 500px;
}

.text-muted {
    --bs-text-opacity: 1;
    color: rgb(0 0 0) !important;
    font-weight: 500;
}

.vehicle-section-bg {
    background-color: #fff;
    padding: 20px;
    height: 330px;
}

#mobile-block {
    display: block;
}

#mobile-block2 {
    display: none;
}
.testimonial-height {
    height: 13.5rem;
}
@media (max-width: 1031px) {
    .why-choose-us {
        margin-top: 8.5rem;
        /* min-height: 500px; */
        margin-top: 245%;
    }
    .testimonial-height {
        height: 13.5rem;
    }
    .home-testimonial {
        background-color: #ececec !important;
        height: 27.5em;
    }

    #mobile-block {
        display: none;
    }

    #mobile-block2 {
        display: block;
    }

    .vehicle-section-bg {
        background-color: #fff;
        padding: 20px;
        min-height: 100px;
        height: 0px;
    }

    /* .card-body {
        padding: 0.5rem;
    } */

    /* .hp-fleet-vehicle {
        margin-top: -99px;
    } */
    .hp-why-choose-us {
        padding-top: 8rem !important;
    }

    .mb-our-values {
        margin: 5px;
    }

    .dropdown-toggle::after {
        display: inline-block;
        margin-left: 0.0em !important;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
    }
}



@media only screen and (min-width: 300px) and (max-width: 430px) {
    .hp-fleet-vehicle {
        margin-top: -129px;
    }
}

@media only screen and (min-width: 430px) {
    .hp-fleet-vehicle {
        margin-top: -1px;
    }
}


@media only screen and (min-width: 390px) and (min-width: 532px) and (max-width: 777px) {
    .hp-fleet-vehicle {
        margin-top: -1px;
    }
}

.custom-margin-top-negative-1 {
    margin-top: -2%;
}

.custom-margin-top-negative-2 {
    margin-top: -8%;
}

.custom-background-color {
    background-color: rgb(245, 123, 32);
}

.bg-cards {
    border: 1px solid #dfdddd;
    background: #ececec;
}