/* body {
    padding: 0;
    font-family: "montserrat", sans-serif;
    min-height: 100vh0;
    background-image: linear-gradient(125deg, #6a89cc, #b8e994);
  } */
.page-not-found-container {
  width: 100%;
  text-align: center;
  color: #343434;
  padding-top: 80px;
  padding-bottom: 80px;
}

.page-not-found-container h1 {
  font-size: 160px;
  margin: 0;
  font-weight: 900;
  letter-spacing: 20px;
  color: black;
  /* background: url(./assets\images\page-not-found-bg.jpg) center; */
  -webkit-text-fill-color: black;
  -webkit-background-clip: text;
}

.page-not-found-container a {
  text-decoration: none;
  background: #10572D;
  color: #ffffff;
  padding: 12px 24px;
  display: inline-block;
  border-radius: 25px;
  font-size: 14px;
  text-transform: uppercase;
  transition: 0.4s;
}

.page-not-found-container a:hover {
  background: #F57B20;
}


/*----- 3.5. Page Title -----*/
.page-not-found-rn-page-title {
  position: relative;
  padding-top: 240px;
  padding-bottom: 100px;
  /* background-image: url(D:\project\web\carsinafrica\frontend\public\assets\images\page-titlebar.jpg); */
  background-attachment: fixed;
  background-size: cover;
}

.page-not-found-rn-page-title .page-not-found-rn-pt-overlayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(5 5 5 / 52%);
}

.page-not-found-rn-page-title .page-not-found-rn-page-title-inner {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-size: 1.14286rem;
}

.page-not-found-rn-page-title .page-not-found-rn-page-title-inner h1 {
  font-size: 41px;
  font-size: 2.92857rem;
  color: #fff;
  font-weight: 700;
  margin-bottom: 15px;
}

.page-not-found-rn-page-title {
  padding-top: 220px;
  padding-bottom: 100px;
}

.page-not-found-rn-page-title .page-not-found-rn-page-title-inner {
  font-size: 14px;
  font-size: 1rem;
}

.page-not-found-rn-page-title .page-not-found-rn-page-title-inner h1 {
  font-size: 32px;
  font-size: 2.28571rem;
}

.page-not-found-rn-page-title {
  padding-top: 180px;
  padding-bottom: 30px;
}

.page-not-found-rn-page-title {
  padding-top: 60px;
  padding-bottom: 60px;
}