.cia-lease-logo{
    width: 230px; 
}
@media (min-width: 250px) and (max-width: 453px) {
.cia-lease-logo{
    width: 150px; 
}
.mob-d-flex{
    display: inline-block !important;
}
}

