.nav-pills-custom .nav-link {
  color: #aaa;
  background: #fff;
  position: relative;
}

.nav-pills-custom .nav-link.active {
  color: #10572d;
  background: #fff;
}


/* Add indicator arrow for the active tab */
@media (min-width: 992px) {
  .nav-pills-custom .nav-link::before {
    content: '';
    display: block;
    border-top: 8px solid transparent;
    border-left: 10px solid #fff;
    border-bottom: 8px solid transparent;
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    opacity: 0;
  }
}

.nav-pills-custom .nav-link.active::before {
  opacity: 1;
}

.nav-link-profile {
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
  border-top: 1px solid #e3e3e3;
  border-radius: 5px;
  text-indent: 5px;
}


.nav-link-profile:last-child {
  border-bottom: none;
  /* Remove bottom border for the last tab */
}

.icon-circle {
  border-radius: 50%;
  background-color: #dddddd;
  padding: 13px;
  color: #000000;
  /* padding-left: 5px; */
  font-size: 20px;
  width: 39px;
  padding-right: 15px;
  padding-left: 6px;
  padding-top: 1px;
  height: 38px;
}

a {
  text-decoration: none;
}

.nav-link-profile.active {
  background-color: #f0f0f0;
  /* Change this to the desired gray color */
}

.nav-link-profile.active .text-decoration-none {
  color: #157347;
}

@media (min-width: 992px) {

  .comp-container,
  .my-settings-edit-row {
    display: flex;
    padding: 0;
  }

  .comp-container__label {
    min-width: 146px;
    overflow-x: hidden;
    position: relative;
    white-space: normal;
    width: 146px;
  }

  .comp-container__element {
    padding-left: var(--bui_spacing_4x);
  }
}

.personal-info {
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.personal-info__item {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 6px;
  margin-bottom: 10px;
  padding-top: 19px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.personal-info__item:hover {
  box-shadow: 2px 2px 2px 2px rgba(150, 150, 150, 0.2);
}

.profile-edit {
  color: #007bff;
}

.profile-edit:hover {
  text-decoration: underline;
}

.edit-button {
  text-align: end;
}

.profile-icon {
  position: absolute;
  font-size: 3.25rem;
  padding-left: 20px;
}

.fw-normal {
  cursor: pointer;
}

.profile-edit {
  color: #f57b20;
  text-decoration: none;
  font-weight: 600;
  padding: 0;
}

.profile-edit:hover {
  color: #d46b1a;
  text-decoration: none;
  font-weight: 600;
  padding: 0;
}

a {
  color: #000;
}

.personal-details-btop {
  border-top: 1px solid #cccccc;
  padding-top: 19px;
}

.active-tab {
  background-color: #f0f0f0;
  color: #157347;
}

.text-color {
  color: #262626;
}

.custom-border-bottom {
  border-bottom: 1px solid #e3e3e3;
}

.light-font-weight {
  font-weight: 400;
}


@media screen and (max-width: 900px) and (min-width: 200px) {
  .profile-top-margin {
    margin-top: 20px;
  }

  .profile-icon {
    position: relative;
    font-size: 3.25rem;
    padding-left: 1px;
  }

  .d-profile {
    display: flex;
    flex-wrap: wrap;
    font-size: 18px;
    text-indent: -1px;
    align-items: center;
  }

  .profile-tab-padding {
    padding: 13px;
    margin-bottom: 9px;
    border: 1px solid #d2d2d2;
  }

  .personal-info {
    padding-bottom: 15px;
    margin-bottom: 15px;
    padding: 9px;
  }
}


.profile-edit:hover{
  color: #ff6f00 !important;
}
.profile-edit{
  color: #F57B20 !important;
}