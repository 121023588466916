/* Example CSS styles for the custom dropdown */
.custom-dropdown {
  position: relative;
  display: inline-block;
}

.selected-item {
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  --bs-dropdown-link-active-bg: #065d29;
  z-index: 1;
}

#cia-nav {
  background-color: #d8d8d8;
  border: none;
  outline: none;
  cursor: pointer;
}

#cia-nav-item {
  margin: auto;
}

#cia-login-header {
  margin-left: 5px;
  color: white;
}

#cia-register-header {
  margin-left: 5px;
  color: white;
}

.rn-icon-content {
  /* display: -webkit-box;
      display: -ms-flexbox; */
  display: flex;
  padding: 13px 16px 0px 0px;
}

.dropdown-menu div {
  display: flex;
  align-items: center;
  padding: 5px;
}

.dropdown-menu div:hover {
  background-color: #fff;
}

/* Custom styles for the dropdown container */
.country-custom-dropdown {
  position: relative;
  width: 200px;
  /* Set the width of the dropdown container */
}

/* Styles for the dropdown options */
.country-custom-dropdown .country-option {
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.country-custom-dropdown .country-option:hover {
  background-color: #f0f0f0;
}

.country-custom-dropdown .country-option img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.country-custom-dropdown .country-option span {
  font-size: 14px;
  color: #333;
}

/* Style the select arrow icon (optional) */
.country-custom-dropdown .react-select__indicator-separator {
  display: none;
}

.country-custom-dropdown .react-select__dropdown-indicator {
  color: #333;
  font-size: 1.2rem;
  cursor: pointer;
}

.country-custom-dropdown .react-select__menu {
  margin-top: 0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

#nav-toggle {
  display: none;
}

#nav-dropDown {
  display: block;
}

.upper-container {
  height: 30px;
}

.sticky-header {
  /* position: sticky; */
  top: 0;
  background-color: #fff;
  /* Add your desired background color */
  z-index: 1000;
}

.upper-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background: #10572d;
}

.icon-container {
  display: flex;
  align-items: center;
}

.globe-icon {
  font-size: 24px;
  margin-right: 10px;
}

.navbar-nav {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin-right: 15px;
  /* Adjust the margin as needed */
}

.nav-link {
  text-decoration: none;
  color: #000;
  /* Add your desired text color */
  font-weight: bold;
  /* Add your desired font weight */
}

.nav-link-location:hover {
  color: red;
  /* Add your desired hover color */
}

.nav-link-location {
  text-decoration: none;
  color: #000;
  /* Add your desired text color */
  font-weight: bold;
  /* Add your desired font weight */
  margin-right: 850px;
}

.nav-link-location-not-available:hover {
  color: red;
  /* Add your desired hover color */
}

.nav-link-location-not-available {
  text-decoration: none;
  color: #000;
  /* Add your desired text color */
  font-weight: bold;
  /* Add your desired font weight */
  margin-right: 750px;
}

.nav-link:hover {
  color: red;
  /* Add your desired hover color */
}


@media (max-width: 575px) {
  #nav-toggle {
    display: block;
    width: -1%;
    position: relative;
  }

  .navbar-nav .dropdown-menu {
    position: absolute;
    margin-left: 0px !important;
  }
  .main-menu-nav-item{
    width: 450px;
  }
  #nav-dropDown {
    display: none;
  }

  .nav-link-location {
    text-decoration: none;
    color: #000;
    font-weight: bold;
    margin-right: 2px;
  }

  .upper-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background: #10572d;
  }
}