#nav-link-location {
  color: rgb(255, 255, 255);
}

#nav-link-global {
  color: rgb(255, 255, 255);
}

/* Example CSS styles for the custom dropdown */
.custom-dropdown {
  position: relative;
  display: inline-block;
}

.selected-item {
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  --bs-dropdown-link-active-bg: #065d29;
  z-index: 1;
}

#cia-nav {
  background-color: #d8d8d8;
  border: none;
  outline: none;
  cursor: pointer;
}

#cia-nav-item {
  margin: auto;
}

#cia-login-header {
  margin-left: 5px;
  color: white;
}

#cia-register-header {
  margin-left: 5px;
  color: white;
}

.rn-icon-content {
  /* display: -webkit-box;
        display: -ms-flexbox; */
  display: flex;
  padding: 13px 16px 0px 0px;
}

.dropdown-menu div {
  display: flex;
  align-items: center;
  padding: 5px;
}

.dropdown-menu div:hover {
  background-color: #fff;
}

.country-custom-dropdown {
  position: relative;
  width: 200px;
}

.country-custom-dropdown .country-option {
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.country-custom-dropdown .country-option:hover {
  background-color: #f0f0f0;
}

.country-custom-dropdown .country-option img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.country-custom-dropdown .country-option span {
  font-size: 14px;
  color: #333;
}

.country-custom-dropdown .react-select__indicator-separator {
  display: none;
}

.country-custom-dropdown .react-select__dropdown-indicator {
  color: #333;
  font-size: 1.2rem;
  cursor: pointer;
}

.country-custom-dropdown .react-select__menu {
  margin-top: 0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

#nav-toggle {
  display: none;
}

#nav-dropDown {
  display: block;
}

.upper-container {
  height: 30px;
}

.sticky-header {
  top: 0;
  background-color: #fff;
  z-index: 1000;
}

.upper-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background: #10572d;
}

.icon-container {
  display: flex;
  align-items: center;
}

.globe-icon {
  font-size: 24px;
  margin-right: 10px;
}

.navbar-nav {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin-right: 15px;
  margin-top: 2px;
}

.nav-link {
  text-decoration: none;
  color: #000;
  font-weight: bold;
}

.nav-link-location:hover {
  color: #f3f3f3;
}

.nav-link-location {
  text-decoration: none;
  color: #000;
  font-weight: bold;
  margin-right: 850px;
}

.nav-link-location-not-available:hover {
  color: #f3f3f3;
}

.nav-link-location-not-available {
  text-decoration: none;
  color: #000;
  font-weight: bold;
  margin-right: 750px;
}

.nav-global:hover {
  color: #f0f0f0;
}

.header-text {
  color: #f0f0f0;
}

@media (min-width: 322px) and (max-width: 1360px) {
  #nav-toggle {
    display: block;
    width: -1%;
    position: relative;
  }

  .navbar-nav .dropdown-menu {
    position: absolute;
    margin-left: -140px;
  }

  .nav-link-location-not-available {
    text-decoration: none;
    color: #ffffff;
    font-weight: bold;
    margin-right: 0px;
  }

  /* #nav-dropDown {
          display: none;
      } */

  .nav-link-location {
    text-decoration: none;
    color: #f7f7f7;
    font-weight: bold;
    margin-right: 2px;
  }
}

/* Adjust size and position of the translate container */
#google_translate_element {
  color: #000;
  display: inline-block;
  width: auto; /* Set width as per your layout */
  font-size: 14px; /* Adjust the font size */
}

/* Target the Google Translate dropdown */
.goog-te-combo {
  width: 150px; /* Adjust width */
  height: 32px; /* Ensure it matches your top bar height */
  padding: 5px; /* Add some padding */
  font-size: 14px; /* Make text consistent with other elements */
  background-color: #fff; /* Background color to match the design */
  border: 1px solid #ccc; /* Add border for better visibility */
  border-radius: 4px; /* Rounded corners */
  outline: none;
}

/* Style the dropdown when hovered */
.goog-te-combo:hover {
  background-color: #f0f0f0;
  border-color: #065d29;
}

/* Align the language dropdown with other elements in the top bar */
.upper-container {
  height: 32px; /* Ensure it's consistent with other components */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Add spacing around the language selector */
#google_translate_element {
  margin-right: 10px; /* Space on the right side */
}
/* Hide only the "Powered by" part */
.VIpgJd-ZVi9od-l4eHX-hSRGPd {
  display: none;
}
.VIpgJd-ZVi9od-ORHb {
  display: block !important;
}

.skiptranslate {
  color: #fff0 !important;
}
.goog-te-gadget .goog-te-combo {
  margin-top: 18px !important;
}

/* Responsive design for the language selector */
@media (max-width: 768px) {
  #google_translate_element {
    width: 100%;
    text-align: center;
  }

  .goog-te-combo {
    width: 100%; /* Full width on smaller screens */
  }
}

.updates-header-date-time{
  color: red;
}

.updates-lease-header{
  background-color: #065d29;
  color: white;
}