.partner {

    width: 60%;

    background: #fff;

    margin: 40px auto;

}

.corporate {
    width: 60%;
    background: #fff;
    margin: 40px auto;
}
@keyframes bounce {
    0% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
    100% { transform: translateY(0); }
}
.custom-text-color {
    color: #f57b20;
}
:where(.css-dev-only-do-not-override-1xg9z9n).ant-input-outlined:focus, :where(.css-dev-only-do-not-override-1xg9z9n).ant-input-outlined:focus-within {
    border-color: #c9c9c9;
}
.custom-submit-button {
    margin-top: 25px;
    background: #10572D !important;
    border-color: #10572D;
}
.login-input {
    /* width: 280px; */
    height: 45px;
    padding: 10px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid #ccc;
}
