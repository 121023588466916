.image-container img {
    max-width: 100%;
    opacity: 1px;
    background-color: #0a0a09;
}

.contact-title {
    position: absolute;
    top: 20%;
    left: 49%;
    transform: translate(-50%, -50%);
    color: orange;
    text-decoration: underline;
    text-decoration-color: green;
}

.custom-container {
    background-color: #353333;
    padding: 52px;
    color: white
}

.contactus-container {
    background-color: #fff;
    margin-top: -140px;
    /* z-index: 0; */
    border-radius: 20px;
    /* opacity: 0.9; */
    margin-left: 167px;
    margin-right: 188px;
}

#name {
    background-color: #fdfdfd;
    border: 1px solid #898b8d;
    border-radius: 5px;
    color: #526977;
    outline: none !important;
    padding: 10px 15px;
    transition: all .3s;
    width: 100%;
}

#email {
    background-color: #fdfdfd;
    border: 1px solid #898b8d;
    border-radius: 5px;
    color: #526977;
    outline: none !important;
    padding: 10px 15px;
    transition: all .3s;
    width: 100%;
}

@media only screen and (min-width: 600px) {
    .contactus-container {
        margin-left: 140px;
        margin-right: 140px;
    }
}

.custom-border {
    border: 2px solid #e9e1e1;
    border-radius: 20px;
    margin-left: 10px;
}

.contact-body-custom {
    height: 174px;
    background-color: #f0ebeb;
    text-align: start 10px;
}

.contact-header {
    background-color: #ffc107;
    color: #333;
}

.contact-title-custom {
    color: #0a0a09;
    margin-left: 12%;
    text-align: center;
    border-radius: 50%;
    margin-top: 2px;
}

.contact-text-custom {
    color: #24680a;
    margin-left: 20%;
    text-align: left;
}

.contact-contact-custom {
    color: #24680a;
    margin-left: 31%;
    text-align: left;
}

.contact-custom {
    width: 21rem;
    float: right;
    margin-right: -75px;
    margin-left: 30px;
    border: 3px solid orange;
    border-radius: 5px;
}

.row-custom-spacing {
    margin-left: 40px;
    margin-right: 69px;
    margin-bottom: 0px;
    background-color: #fff;
    padding-top: 80px;
}

.phoneinputfield {
    background-color: #f9f9f9 !important;
    border-radius: 5px;
    border: none !important;
    padding: 3px;
    width: 100%;
    color: black;
    justify-content: center;
}

.PhoneInputInput{
    background-color: #f9f9f9 !important;
}

#mobile-number {
    padding: 0px !important;
    border: none;
    margin-bottom: 5px;
    background: #f9f9f9;
}

.contact-btn {
    display: inline-block;
    padding: 8px 20px 10px 10px;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    font-weight: bold;
    color: #fff;
    width: auto;
    height: 40px;
    margin-left: 26%;
    margin-top: auto;
}

.contact-us-ssumit-btn{
    color: white;
    font-weight: 600;
}

.contact-title i {
    border-radius: 100%;
    background-color: #f90;
    color: #fff;
}

.top-white-border {
    border-top: 90px solid rgb(211, 208, 208);
    z-index: 30;
}

.new-button {
    background-color: rgb(43, 97, 52);
    color: white;
    padding: 10px 10px;
    border: 2px;
    cursor: pointer;
    border-radius: 10px;
    width: 120px;
}

.row justify-content-end {
    padding-top: 600px;
    padding-bottom: 200px;
    padding-left: 50-px;
    border-top-right-radius: 50px;
    --bs--gutter-x: 80px;
}

#message {
    display: block;
    width: 100%;
    padding: 7px 5px 4px 10px;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.custom-image-fluid {
    width: 52px;
    height: 48px;
    margin-bottom: -91px;
    padding-left: 5px;
}

@media screen and (max-width: 900px) and (min-width: 200px) {
    .contact-custom {
        width: 100%;
        float: initial;
        margin-right: -0px;
        margin-left: 0px;
        border: 3px solid orange;
        border-radius: 5px;
    }

    .contact-body-custom {
        height: 140px;
        background-color: #f0ebeb;
        text-align: start 10px;
    }

    .contact-btn {
        display: inline-block;
        padding: 8px 20px 10px 10px;
        text-align: center;
        text-decoration: none;
        border-radius: 4px;
        font-weight: bold;
        color: #fff;
        width: auto;
        height: 40px;
        margin-left: 30%;
        margin-top: auto;
    }

    .custom-image-fluid {
        width: 60px;
        height: 55px;
        margin-bottom: -91px;
        padding-left: 5px;
        margin-left: 35px;
    }

    .contactus-container {
        background-color: #fff;
        margin-top: -140px;
        border-radius: 20px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .row-custom-spacing {
        margin-left: 0px;
        margin-right: 0%;
        margin-bottom: 0px;
        background-color: #fff;
        padding-top: 80px;
    }

    .contact-text-custom {
        color: #24680a;
        margin-left: 34%;
        text-align: left;
    }
}

@media (min-width: 1200px) {
    .full-width {
        max-width: 1500px;
    }
}

.contact {
    padding: 3rem 20px;
}

.contact .heading h2 {
    font-size: 30px;
    font-weight: 700;
    margin: 0;
    padding: 0;
}

.contact .heading h2 span {
    color: #f57b20;
}

.contact .heading p {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.7;
    color: #878787;
    margin: 20px 0 60px;
    padding: 0;
}

.contact .form-control {
    padding: 17px;
    font-size: 13px;
    margin-bottom: 10px;
    background: #f9f9f9;
    border: 0;
    border-radius: 10px;
}

.contact button.btn {
    padding: 10px;
    border-radius: 10px;
    font-size: 15px;
    background: #ff9100;
    color: #ffffff;
}

.contact .title h3 {
    font-size: 18px;
    font-weight: 600;
}

.contact .title p {
    font-size: 14px;
    font-weight: 400;
    color: #999;
    line-height: 1.6;
    margin: 0 0 40px;
}

.contact .contact-us-content .info {
    margin-top: 30px;
    display: flex;
}

.contact .contact-us-content .info i {
    font-size: 30px;
    padding: 0;
    margin: 0;
    color: #10572d;
    margin-right: 20px;
    text-align: center;
    width: 20px;
}

.contact .contact-us-content .info h4 {
    font-size: 13px;
    line-height: 1.4;
}

.contact .contact-us-content .info h4 span {
    font-size: 16px;
    font-weight: 400;
    color: #7d7d7d;
}

.container .contact-us-content {
    /* display: flex; */
    align-items: center;
    justify-content: space-between;
}

.box-shadow {
    width: 60%;
    background: #fff;
    margin: 40px auto;
    background: #fff;
    -moz-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 1px 1px 20px 5px rgb(0 0 0 / 18%);
    -o-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    -o-border-radius: 20px;
    -ms-border-radius: 20px;
}

@media (max-width: 584px) {
    .box-shadow {
        width: 100%;
    }
}

.btn-block{
    background-color: #FF9100 !important;
}