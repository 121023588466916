.cia-our-services-heading {
  font-size: 32px;
  font-weight: bold;
}
.cia-services-underline {
  width: 750px;
  height: 1px;
  background-color: #10572d;
}
.rounded-discover-features-img {
  border-radius: 100px;
  padding: 20px;
  width: 620px;
  height: 350px;
}
.cia-services-discription {
  font-size: 18px;
  font-weight: 400;
  text-align: justify;
}

@media (max-width: 575px) {
  .rounded-discover-features-img {
    border-radius: 100px;
    padding: 20px 20px 20px 0px;
    width: 100%;
    height: auto;
  }
}

