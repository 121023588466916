.custom-select {
    appearance: none;
    border: 0;
    box-shadow: none;
    flex: 1;
    color: #fff;
    background-color: var(--darkgray);
    background-image: none;
    cursor: pointer;
}

.custom-select::-ms-expand {
    display: none;
}

.custom-select {
    position: relative;
    display: flex;
    border-radius: .25em;
    overflow: hidden;
    width: 350px;
}

.custom-select::after {
    content: '\25BC';
    position: absolute;
    top: -2px;
    right: 0;
    padding: 1em;
    background-color: #7e7e7e;
    transition: .25s all ease;
    pointer-events: none;
}

.custom-select:hover::after {
    color: #f57b20;
}

.down_note {
    display: flex;
    justify-content: center;
}

.custom-select-sort {
    appearance: none;
    border: 0;
    box-shadow: none;
    flex: 1;
    color: #fff;
    background-color: var(--darkgray);
    background-image: none;
    cursor: pointer;
}

.custom-select-sort::-ms-expand {
    display: none;
}

.custom-select-sort {
    position: relative;
    display: flex;
    border-radius: .25em;
    overflow: hidden;
    width: 12.6rem;
}

.custom-select-sort::after {
    content: '\25BC';
    position: absolute;
    top: -2px;
    right: 0;
    padding: 1em;
    background-color: #7e7e7e;
    transition: .25s all ease;
    pointer-events: none;
}

.custom-select-sort:hover::after {
    color: #f57b20;
}

.down_note {
    display: flex;
    justify-content: center;
}

@media (max-width: 770px) {
    .custom-select-sort {
        position: relative;
        display: flex;
        border-radius: 0.25em;
        overflow: hidden;
        width: 10.5rem;
        float: inline-end;
        margin-top: -54px;
        margin-bottom: auto;
    }

}

@media screen and (max-width: 900px) and (min-width: 200px) {
    .custom-select {
        position: relative;
        display: flex;
        border-radius: 0.25em;
        overflow: hidden;
        width: 8.5rem;
        margin-right: 20px;
    }

    .custom-select::after {
        content: '\25BC';
        position: absolute;
        top: 10px;
        right: 0;
        padding: 0.8em;
        background-color: #7e7e7e;
        transition: .25s all ease;
        pointer-events: none;
    }



    .custom-select-sort::after {
        content: '\25BC';
        position: absolute;
        top: 10px;
        right: 0;
        padding: 0.8em;
        background-color: #7e7e7e;
        transition: .25s all ease;
        pointer-events: none;
    }
}


.vehicle-image {
    max-width: 100%;
    height: auto;
}

.icon {
    width: 20px;
    height: 20px;
}

.vehicle-details {
    display: flex;
    flex-wrap: wrap;
}

.vehicle-details span {
    margin-right: 20px;
}

.custom-container-gif {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40vh;
}

.custom-vehicle-not-available {
    display: flex;
    flex-direction: column;
}

.carlist-image {
    width: 115%;
}

.custom-card-vehicle {
    box-shadow: 0px 0px 8px 0px #80808045;
}

.vehicle-span {
    text-indent: 2px;
}

.custom-bold {
    font-size: 15px;
    color: #f57b20;
}

.custom-section-rn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
}

.custom-page-item {
    padding: 5px;
}

.custom-bold-left {
    padding-left: 5px;
}

@media (max-width: 545px) {
    .car-list-data {
        padding: 10px
    }
}

.custom-link-button {
    background: rgb(245, 123, 32);
    border: none;
    font-size: large;
}

.custom-link-button:hover {
    background: rgb(218, 110, 28);
    border: none;
    font-size: large;
}

.custom-link-rn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


@media (max-width: 545px) {
    .vehicle-information-col {
        padding: 0px 15px 0px 15px;
        text-align: justify;
    }

    .book-now-btn {
        text-align: end;
    }

    .carlist-image {
        width: 100%;
    }

    .mb-carlist-padding {
        padding: 20px;
    }
}

.custom-select .dropdown-item2 option:hover {
    background-color: #dc3545;
    color: #fff;
}