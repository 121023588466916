@media (min-width: 1200px) {
    .full-width {
        max-width: 1500px;
    }
}

@media (max-width: 545px) {
    .who-we-are-description {
        padding: 10px;
        text-align: justify;
    }
}
.who-we-are-description {
    
    text-align: justify;
}

.icon-container-aboutus {
    display: flex;
    align-items: center;
    border: 1px solid #a0a0a0;
    padding: 11px;
    border-radius: 35px;
}


.bsb-btn-circle {
    align-items: center;
    backface-visibility: hidden;
    border-radius: 50% !important;
    display: inline-flex;
    height: 2.5rem;
    justify-content: center;
    line-height: 1;
    padding: 0;
    width: 2.5rem
}

.bsb-circle {
    --bsb-cs: 125px;
    border-radius: 50%;
    height: var(--bsb-cs);
    width: var(--bsb-cs);
    background: #f57b20;
}

.bg-aboutus {
    background: #e8e8e8;
}

.bsb-navbar-hover .dropdown-menu.show.bsb-fadeIn {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeIn
}

.bsb-navbar-hover .dropdown-menu.show.bsb-fadeInUp {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeInUp
}

.bsb-navbar-hover .dropdown-menu.show.bsb-fadeInDown {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeInDown
}

.bsb-navbar-hover .dropdown-menu.show.bsb-fadeInLeft {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeInLeft
}

.bsb-navbar-hover .dropdown-menu.show.bsb-fadeInRight {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeInRight
}

.bsb-navbar-hover .dropdown-menu.show.bsb-zoomIn {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-zoomIn
}




.bsb-navbar.navbar-expand.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInDown,
.bsb-navbar.navbar-expand.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInDown {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeInDown
}

.bsb-navbar.navbar-expand.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInLeft,
.bsb-navbar.navbar-expand.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInLeft {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeInLeft
}

.bsb-navbar.navbar-expand.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInRight,
.bsb-navbar.navbar-expand.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInRight {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeInRight
}

.bsb-navbar.navbar-expand.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-zoomIn,
.bsb-navbar.navbar-expand.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-zoomIn {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-zoomIn
}

.bsb-navbar:not(.bsb-navbar-caret) .dropdown-toggle:after {
    display: none
}

.bsb-overlay {
    --bsb-overlay-opacity: 0.5;
    --bsb-overlay-bg-color: var(--bs-black-rgb);
    position: relative
}

.bsb-overlay:after {
    background-color: rgba(var(--bsb-overlay-bg-color), var(--bsb-overlay-opacity));
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0
}

.bsb-overlay>* {
    position: relative;
    z-index: 1
}

.bsb-overlay-figure {
    --bsb-overlay-figure-opacity: 0.5;
    --bsb-overlay-figure-bg-color: var(--bs-black-rgb);
    position: relative
}

.bsb-overlay-figure:after {
    background-color: rgba(var(--bsb-overlay-figure-bg-color), var(--bsb-overlay-figure-opacity));
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.bsb-overlay-hover {
    --bsb-overlay-hover-opacity: 0.5;
    --bsb-overlay-hover-bg-color: var(--bs-black-rgb);
    position: relative
}

.bsb-overlay-hover>a {
    bottom: 0;
    display: block;
    left: 0;
    position: relative;
    right: 0;
    top: 0
}

.bsb-overlay-hover>a>img.bsb-scale {
    --bsb-scale: 1
}

.bsb-overlay-hover>a>img.bsb-scale,
.bsb-overlay-hover>a>img.bsb-scale-up {
    transform: scale3d(var(--bsb-scale), var(--bsb-scale), var(--bsb-scale));
    transform-style: preserve-3d;
    transition: transform .5s
}

.bsb-overlay-hover>a>img.bsb-scale-up {
    --bsb-scale: 1.2
}

.bsb-overlay-hover>a:after {
    background-color: rgba(var(--bsb-overlay-hover-bg-color), var(--bsb-overlay-hover-opacity));
    content: "";
    cursor: pointer !important;
    display: block;
    z-index: 0
}

.bsb-overlay-hover>a:after,
.bsb-overlay-hover>figcaption {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0
}

.bsb-overlay-hover>figcaption {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    pointer-events: none;
    z-index: 1
}

.bsb-overlay-hover>figcaption>* {
    opacity: 0
}

.bsb-overlay-hover:hover>a>img.bsb-hover-scale {
    --bsb-scale-hover: 1;
    transform: scale3d(var(--bsb-scale-hover), var(--bsb-scale-hover), var(--bsb-scale-hover))
}

.bsb-overlay-hover:hover>a>img.bsb-hover-scale-up {
    --bsb-scale-hover: 1.2;
    transform: scale3d(var(--bsb-scale-hover), var(--bsb-scale-hover), var(--bsb-scale-hover))
}

.bsb-overlay-hover:hover>a:after {
    opacity: 1;
    transition: opacity .15s linear
}

.bsb-overlay-hover:hover>figcaption {
    opacity: 1;
    transition: opacity .15s linear .1s
}

.bsb-overlay-hover:hover>figcaption>.bsb-hover-fadeIn {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeIn
}

.bsb-overlay-hover:hover>figcaption>.bsb-hover-fadeInUp {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeInUp
}

.bsb-overlay-hover:hover>figcaption>.bsb-hover-fadeInDown {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeInDown
}

.bsb-overlay-hover:hover>figcaption>.bsb-hover-fadeInLeft {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeInLeft
}

.bsb-overlay-hover:hover>figcaption>.bsb-hover-fadeInRight {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeInRight
}

.bsb-overlay-hover:hover>figcaption>.bsb-hover-zoomIn {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-zoomIn
}

.bsb-cta-2 .card {
    background-attachment: fixed;
    background-position: 50%;
    background-size: cover
}

.text-orange {
    color: #F57B20;
}

.bg-contact-us {
    background: #10572d;
}

.bg-contact-us:hover {
    background: #10572d !important;
}

.border-primary {
    border-color: #157347 !important;
}

.typing-text {
    display: inline-block;
    white-space: nowrap; 
    overflow: hidden;    
    animation: typing 4s steps(30, end); 
  }
  
  /* Typing animation */
  @keyframes typing {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  