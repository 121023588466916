/* flag.css */
/* Add your custom styles here */
/* For illustration purposes, let's style the dropdown with a light theme */

/* Styling the container */
.flag-select {
  position: relative;
  display: inline-block;
}

/* Styling the dropdown button */
.flag-select .selected-flag {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
}

/* Styling the dropdown list */
.flag-select .flag-options {
  position: absolute;
  top: 100%;
  left: 0;
  color: #000000 !important;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}

/* Styling each flag option */
.flag-select .flag-option {
  padding: 8px;
  cursor: pointer;
}

.flag-select .flag-option:hover {
  background-color: #000000;
}

#rfs-btn.ReactFlagsSelect-module_selectBtn__19wW7 {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 5px;
  font-family: inherit;
  color: #4d4d4d;
  border: thin solid rgb(62 62 62);
  border-radius: 4px;
  background: white !important;
}

.ReactFlagsSelect-module_flagsSelect__2pfa2 {
  position: relative;
  vertical-align: inherit;
  padding-bottom: 5px;
  text-align: left;
  color: black;
}

.custom-select-dropdown {
  padding: 18px 36px 16px 16px;
  appearance: none;
  border: none;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-color: #f2f2f2;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" stroke="none"><path d="M7 10l5 5 5-5z" /></svg>');
  background-repeat: no-repeat;
  background-position: right 7px center;
  background-size: 24px 28px;
}

.custom-select-dropdown:focus {
  outline: none;
  box-shadow: none;
}

.autocomplete-wrapper {
  position: relative;
}

.autocomplete-wrapper {
  position: relative;
}

.pickup_local {
  padding-right: 30px;
}

.autocomplete-wrapper {
  position: relative;
}

.pickup_local {
  padding-right: 30px;
}

.clear-button {
  display: none;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.bg-opacity-80 {
  background: #00000080;
}

.custom-background {
  background: #125d30;
}

.custom-margin-left-5 {
  margin-left: 5px;
}

.custom-flex {
  display: flex;
  align-items: center;
}

.custom-margin-top-5 {
  margin-top: 5px;
}

.custom-input {
  width: 280px;
  height: 60px;
  padding: 10px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.custom-no-border {
  border: none;
}

.clear-button::before,
.clear-button::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 12px;
  background-color: black;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

#select-trip {
  color: #4d4d4d !important;
  text-shadow: 0px 0px 0px #4d4d4d !important;
}

.clear-button::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.custom-no-border:hover {
  background: #115c2f !important;
  color: #ffffff;
}

@media (max-width: 991px) {
  .custom-select-dropdown {
    padding: 18px 36px 16px 16px;
    appearance: none;
    border: none;
    border-radius: 5px;
    border: 1px solid #9d9d9d;
    background-repeat: no-repeat;
    background-color: #fdfdfd;
  }
}

@media (max-width: 845px) {
  .local-full-day {
    width: 90px !important;
  }

  .local-half-day {
    position: absolute;
    width: 90px !important;
    margin-top: -375px !important;
    margin-left: 102px;
    text-indent: -4px;
  }

  .mb-checkbox {
    margin-top: -72px;
  }

  .mb-height {
    padding: 15px;
  }

  /* .local-half-day, .clicked {
    position: absolute;
    margin-top: -374px;
    margin-left: 102px;
    text-indent: -4px;
  } */

  .local-full-day,
  .local-half-day {
    flex-basis: auto;
    /* Reset width to auto for vertical stacking */
  }

  .pac-target-input {
    width: -webkit-fill-available;
    width: -moz-available;
    height: 60px;
    padding: 10px;
    margin-top: 5px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid #3c3c3c;
    box-shadow: #fff;
  }

  .self-drive-padding {
    padding-top: 0px !important;
  }

  .self-drive-checkbox {
    background: #115c2f;
  }
}

@media (min-width: 1200px) {
  .col-xl-2 {
    flex: 0 0 auto;
    width: auto !important;
  }
}

@media (min-width: 1200px) {
  #rfs-btn.ReactFlagsSelect-module_selectBtn__19wW7 {
    width: 100% !important;
  }
}

/* .row-1 {
  padding: 10px;
} */

input.self-drive-checkbox {
  accent-color: green;
}


.ReactFlagsSelect-module_label__27pw9, .ReactFlagsSelect-module_secondaryLabel__37t1D {
  width: 100px !important;
}

.ReactFlagsSelect-module_flagsSelect__2pfa2{
  width: 160px !important;
}

.btn-primary:hover {
  background-color: #115c2f !important;
}

.btn-primary {
  background-color: #115c2f !important; /* Adjust this color as needed */
}

@media (min-width: 1200px) {
  #header-width {
      max-width: fit-content !important;
  }
}