.enquiry-width {
    display: flex;
}

.corporate-width {
    padding-left: 0px;
    margin-top: 0px;
}

.business-width {
    padding-left: 0px;
    margin-top: 0px;
}

.corporate-label {
    padding-left: 0px;
    margin-top: 0px;
}

.business-label {
    padding-left: 0px;
    margin-top: 0px;
}

@media (max-width: 576px) {
    .enquiry-width {
        display: flex;
        flex-direction: column;
    }
    .circle-radio {
        padding-left: 2px !important;
    }
    .form-check-inline {
        margin-bottom: 10px;
    }

    .enquiry-custom {
        margin-right: 19rem;
    }

    .enquiy-form {
        display: block;
        width: 100%;
        padding: 1px 2px 2px 1px;
        font-size: 1rem;
        /* line-height: 1.5; */
        color: #495057;
        margin-bottom: 0px; 
        border: 1px solid gray;
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  
}