@media (min-width: 1200px) {
  .full-width {
    max-width: 1500px;
  }
}
.underline {
  width: 250px;
  height: 8px;
  background-color: #10572d;
}
.cia-contact-us-btn {
  background-color: #f57b20;
  width: 145px;
  height: 52px;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #10572d;
  color: white;
  margin-left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease; 
}
.cia-contact-us-btn:hover {
  background-color: #10572d; 
  color: #ffffff; 
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); 
  transform: scale(1.05); 
}
.cia-contact-us-btn:active{
  background-color: #10572d !important; 
  color: #ffffff !important; 
}

.cia-service-discription {
  font-size: 18px;
  cursor: default;
  text-align: justify;
}
.cia-why-choose-heading {
  font-size: 32px;
  font-weight: bold;
}

.card {
  border: none;
  transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 20px;
  box-shadow: 0 4px 15px #10572d7a;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px #10572d7a;
}

.why-choose-card-icon {
  height: 70px;
  width: 70px;
}
.cia-why-choose-underline {
  width: 750px;
  height: 2px;
  background-color: #10572d;
}
.cia-website {
  color: #f57b20;
}
.why-choose-card-text {
  color: #555;
  font-size: 15px;
  font-weight: normal;
}
.cia-services-card-title {
  font-size: 20px;
}

.heading-with-underline {
  width: 70px;
  height: 2px;
  background-color: #10572d;
}
.cia-emp-experience{
  font-size: 18px;
  font-weight: 300 !important;
  cursor: default;
  text-align: justify;
}
.accordion-item {
  border-right: none;
  border-left: none;
  padding: 15px 5px;
  border: none; 
  margin-bottom: 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  overflow: hidden;
}
.accordion-button {
  font-size: 22px;
  font-weight: 500;
  color: #000000 !important;
  padding: 15px;
  border-radius: 8px 8px 0 0;
  transition: background-color 0.3s ease, color 0.3s ease;
}


.accordion-button:focus {
  box-shadow: none;
}
.accordion-button:not(.collapsed) {
  background-color: #fff;
  color: #f57b20 !important;
}

.accordion-body 
{
  font-weight: 300;
  font-size: 18px;
  background-color: #f9f9f9;
  padding: 20px;
  color: #555; 
}

.key-features-underline {
  width: 150px;
  height: 2px;
  background-color: #10572d;
}
.cia-chauffeur-underline{
  width: 550px;
  height: 2px;
  background-color: #f57b20;
}
.cia-transfer-image {
  width: 456px;
  height: 265px;
}
.cia-chauffeur-drive-service-container{
  border-radius: 30px;
  padding: 25px;
}
.cia-chauffeur-ride-underline{
  width: 250px;
  height: 2px;
  background-color: #10572d;
}
.cia-vehicle-class-container{
  border-radius: 30px;
}
.cia-cars-data{
  padding-left: 50px;
}

.custom-height-vehicle{
  padding-top: 30px;
} 

@media (max-width: 767px) {
  .cia-cars-data{
    padding-left: 10px !important;
  }
  .cia-transfer-image {
    height: auto;
    width: 100%;
  }
  .custom-height-vehicle{
    padding-top: 0px !important;
  } 
}


/* General reset for hidden elements */
.slide-up,
.zoom-in {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s ease-in-out;
}


.accordian-viewMore-btn{
  background-color: #18551c;
  border-color: #18551c;
  width: 100px;
  color: #fff;
}
.accordian-viewMore-btn:hover{
  background-color: #18551c;
  border-color: #18551c;
  box-shadow: 0px 2px 4px rgba(59, 158, 76, 0.644);
  transform: scale(1.05);
}
.accordian-viewMore-btn:focus {
  background-color: #18551c;
  border-color: #18551c;
  color: #fff;
}
.accordian-viewMore-btn:active{
  background-color: #18551c !important;
  border-color: #18551c !important;
  color: #fff !important;
}

/* Slide-up animation */
.slide-up.show {
  opacity: 1;
  transform: translateY(0);
}

.slide-up.hide {
  opacity: 0;
  transform: translateY(50%);
}

/* Zoom-in animation */
.zoom-in {
  transform: scale(0.9);
}

.zoom-in.zoom-show {
  opacity: 1;
  transform: scale(1);
}

.zoom-in.zoom-hide {
  opacity: 0;
  transform: scale(0.6);
}

.zoom-out {
  transform: scale(1.1); 
  opacity: 0;          

}

.zoom-out.zoom-out-show {
  opacity: 1;          
  transform: scale(0.9);  
}

.zoom-out.zoom-out-hide {
  opacity: 0;         
  transform: scale(1.0); 
}


