.footer-contact-us-width {
  margin-left: -15px;
}

.bg-footer {
  background: #10572d;
}

.login-bg-color {
  background: #f57b20;
}

.partner-vector {
  width: 20px;
  height: 20px;
  filter: brightness(0) invert(1);
}

.ml-partner {
  margin-left: 5px;
}
.ml-corporate {
  margin-left: 5px;
}
.footer-margin-height {
  height: 20px;
}
.partner-corporate-bg {
  background: #f57b20;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.375rem;
  color: white;
  font-weight: 500;
}
.partner-corporate-bg:hover {
  background: #ff6f00;
}

.btn-corporate-size {
  width: 20px;
  height: 20px;
  filter: brightness(0) invert(1);
}
.support-box {
  border: 2px solid #f57b20;
  padding: 10px 0px 0px 4px;
  display: inline-block;
  width: 100%;
  margin-left: -7%;
  max-width: 90%;
  background: #10572d;
  border-radius: 5px;
}

.btn-bg-dashboard {
  background: #f57b20;
}
.btn-dashboard-size {
  width: 20px;
  height: 20px;
  filter: brightness(0) invert(1);
}
.dashboard-ml-btn {
  margin-left: 5px;
}


/* General styling for social links list */
.social-links {
    display: flex;
    gap: 10px; /* Optional, adds space between items */
  }
  
  /* General styling for all social items */
  .social-item {
    list-style: none;
  }
  
  /* Anchor tag styling for social links */
  .social-link {
    text-decoration: none;
  }
  
  /* Facebook button */
  .btn-facebook {
    background-color: #3b5998; /* Original Facebook blue */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    transition: background-color 0.3s ease;
  }
  
  .btn-facebook:hover {
    background-color: #2d4373; /* Slightly darker blue for hover effect */
  }
  
  /* Twitter button */
  .btn-twitter {
    background-color: #000000; /* Original Twitter blue */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    transition: background-color 0.3s ease;
  }
  
  .btn-twitter:hover {
    background-color: #0e0e0e; /* Slightly darker blue for hover effect */
  }
  
  /* Instagram button */
  .btn-instagram {
    background: linear-gradient(
      45deg,
      #f09433,
      #e6683c,
      #dc2743,
      #cc2366,
      #bc1888
    ); /* Original Instagram gradient */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 12px;
    transition: opacity 0.3s ease;
  }
  
  .btn-instagram:hover {
    opacity: 0.8; /* Reduce opacity for hover effect */
  }
  
  /* WhatsApp button */
  .btn-whatsapp {
    background-color: #25d366; /* Original WhatsApp green */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 12px;
    transition: background-color 0.3s ease;
  }
  
  .btn-whatsapp:hover {
    background-color: #1ebe57; /* Slightly darker green for hover effect */
  }
  
  /* Optional: General button styling for consistency */
  button.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  