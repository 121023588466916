.registration-shadow {
  background: #fff;
  margin: 0 auto;
  /* box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05); */
  -moz-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 1px 1px 20px 5px rgb(0 0 0 / 18%);
  -o-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  -o-border-radius: 20px;
  -ms-border-radius: 20px;
}

.custom-height {
  height: 97%;
  padding: 25px;
}

.custom-textarea {
  padding: 10px 15px;
  border-radius: 5px;
}

.custom-input-password {
  padding: 0px 10px;
}

.custom-gender {
  padding-left: 0px !important;
}

.male-gender {
  margin-top: 0px !important;
}

.female-gender {
  margin-top: 0px !important;
}

.reg-button {
  background: #f57b20 !important;
}

.confirm-password {
  padding-left: 5px;
}

#mobile-number {
  background-color: white;
}

.custom-register-container{}