/* Example CSS styles for the custom dropdown */
.custom-dropdown {
  position: relative;
  display: inline-block;
}

.selected-item {
  cursor: pointer;
}
.lease-link {
  background: #10572d;
  color: white;
}
.lease-link:hover {
  background: #10572d;
  color: white;
}
.lease-link:active {
  background: #10572d;
  color: white;
}
.menu-alignment {
  margin-top: 5px !important;
}
.cia-header-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  --bs-dropdown-link-active-bg: #065d29;
  z-index: 1;
}

#cia-nav {
  background-color: #d8d8d8;
  border: none;
  outline: none;
  cursor: pointer;
}

#cia-nav-item {
  margin: auto;
}

#cia-login-header {
  margin-left: 5px;
  color: white;
}

#cia-register-header {
  margin-left: 5px;
  color: white;
}

.rn-icon-content {
  /* display: -webkit-box;
      display: -ms-flexbox; */
  display: flex;
  padding: 13px 16px 0px 0px;
}

.cia-header-dropdown div {
  display: flex;
  align-items: center;
  padding: 5px;
}

.cia-header-dropdown div:hover {
  background-color: #fff;
}

/* Custom styles for the dropdown container */
.country-custom-dropdown {
  position: relative;
  width: 200px;
  /* Set the width of the dropdown container */
}

/* Styles for the dropdown options */
.country-custom-dropdown .country-option {
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.country-custom-dropdown .country-option:hover {
  background-color: #f0f0f0;
}

.country-custom-dropdown .country-option img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.country-custom-dropdown .country-option span {
  font-size: 14px;
  color: #333;
}

/* Style the select arrow icon (optional) */
.country-custom-dropdown .react-select__indicator-separator {
  display: none;
}

.country-custom-dropdown .react-select__dropdown-indicator {
  color: #333;
  font-size: 1.2rem;
  cursor: pointer;
}

.country-custom-dropdown .react-select__menu {
  margin-top: 0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

#nav-toggle {
  display: none;
}

#nav-dropDown {
  display: block;
}

.upper-container {
  height: 30px;
}

.sticky-header {
  /* position: sticky; */
  top: 0;
  background-color: #fff;
  /* Add your desired background color */
  z-index: 1000;
}

.upper-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background: #10572d;
}

.icon-container {
  display: flex;
  align-items: center;
}

.globe-icon {
  font-size: 24px;
  margin-right: 10px;
}

.navbar-nav {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin-right: 15px;
  /* Adjust the margin as needed */
}

.nav-link {
  text-decoration: none;
  color: #000;
  /* Add your desired text color */
  font-weight: bold;
  /* Add your desired font weight */
}

.nav-link-location:hover {
  color: red;
  /* Add your desired hover color */
}

.nav-link-location {
  text-decoration: none;
  color: #000;
  /* Add your desired text color */
  font-weight: bold;
  /* Add your desired font weight */
  margin-right: 850px;
}

.nav-link-location-not-available:hover {
  color: red;
  /* Add your desired hover color */
}

.nav-link-location-not-available {
  text-decoration: none;
  color: #000;
  /* Add your desired text color */
  font-weight: bold;
  /* Add your desired font weight */
  margin-right: 750px;
}

.nav-link:hover {
  color: red;
  /* Add your desired hover color */
}

@media (max-width: 575px) {
  #nav-toggle {
    display: block;
    width: -1%;
    position: relative;
  }

  .navbar-nav .cia-header-dropdown {
    position: absolute;
    margin-left: -140px;
  }

  #nav-dropDown {
    display: none;
  }

  .nav-link-location {
    text-decoration: none;
    color: #000;
    font-weight: bold;
    margin-right: 2px;
  }
}

.menu-nav-margin {
  margin: 5px;
}

.main-menu-bg-button {
  background: #f57b20;
}

.main-menu-login {
  filter: brightness(0) invert(1);
}

.main-menu-registration-bg {
  background: #f57b20;
}

.btn-main-menu-registration {
  filter: brightness(0) invert(1);
}

.dashboard-auto-margin {
  margin: auto;
}
.main-menu-dropdown-margin {
  margin: auto;
}

@media (min-width: 981px) and (max-width: 1294px) {
  .tablet-view {
    width: 620px;
    font-size: 14px;
    text-wrap: nowrap;
  }
  .tablet-navbar-brand {
    width: 135px;
  }
  .cia-logo-tablet {
    width: 142%;
  }
  .main-menu-nav-item {
    margin-right: 0px !important;
  }
  .nav-bar-tablet {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    margin-left: 7%;
    justify-content: flex-end !important;
  }
}

/* @media (min-width: 981px) and (max-width: 1309px) {
  .small-profile-circle{
    display: none !important;
  }
} */

/* #google_translate_element {
    width: 150px; 
    height: 20px; 
    border: 1px solid #ccc;
    padding: 5px; 
    background-color: #f9f9f9; 
    border-radius: 4px; 
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  }

  .goog-te-combo {
    width: 100%;
    height: 100%;
    font-size: 14px;
    border: none;
    outline: none;
  } */

.nav-item.dropdown:hover .cia-header-dropdown {
  display: block;
}
.cia-sub-dropdown-item{
  font-weight: 500;
  border-bottom: 1px solid #0000003b;
  height: 40px;
  display: flex;
  align-items: center;
}

.cia-sub-dropdown-item:hover{
  color: red;
}
.cia-sub-dropdown-item.active, .cia-sub-dropdown-item:active {
background-color: #fff;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .nav-item.dropdown .cia-header-dropdown {
    display: none;
    position: static;
    background-color: #ffffff;
    border: none;
  }

  .nav-item.dropdown:hover .cia-header-dropdown {
    display: block;
  }

  .cia-header-dropdown .dropdown-item {
    padding-left: 0px;
    height: 40px;
    border-bottom: 1px solid #0000003b;
    display: flex;
    align-items: center;
  }

  .navbar-nav {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-nav .nav-item {
    margin-bottom: 10px;
  }
}
